/* eslint-disable no-debugger */
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { Trans } from "react-i18next";
import qs from "qs";

import "../../App.css";
import PfnKey from "../../PfnKey";
import PasswordManager from "../../backend/PasswordManager";
import { withRouter } from "../../withRouter";
import styled from "styled-components";
import { message, Alert } from "antd";
const ResetPasswordBox = styled.div`
  .login_form {
    width: 100%;
    margin-top: 20px;
    position: relative;

    input {
      font-family: inherit;
      width: 100%;
      outline: none;
      background-color: #fff;
      border-radius: 4px;
      border: none;
      display: block;
      padding: 0.9rem 0.7rem;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      font-size: 17px;
      color: #4a4a4a;
      text-indent: 40px;
    }

    .form-field {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 1rem;
      position: relative;

      &:before {
        font-size: 20px;
        position: absolute;
        left: 15px;
        top: 17px;
        color: #888888;
        content: " ";
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    .user::before {
      background-image: url(http://localhost:3000/user-icon.png);
      width: 20px;
      height: 20px;
      top: 15px;
    }

    .pwd::before {
      background-image: url(http://localhost:3000/lock-icon.png);
      width: 16px;
      height: 16px;
    }

    .btn {
      width: 100%;
      margin: 0 auto;
      padding: 10px 30px;
      text-align: center;
      background: #00592a;
      color: #fff;
      border-radius: 4px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      font-size: 17px;

      &:nth-child(2) {
        margin-left: 20px;
        background: #da4207;
      }
    }

    .btnset {
      display: flex;
    }
  }
  .login {
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    padding: 20px;
    margin: 20px auto;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    background: url("http://localhost:8080/background/grey.png");
    justify-content: space-evenly;
    background: white;
    img {
      max-width: 500px;
    }
  }
  .footer {
    font-size: small;
    text-align: center;
    color: #fff;
  }
`;
const ResetPassword = (props) => {
  const [userEmail, setUserEmail] = useState("");
  const [jwt, setJwt] = useState(undefined);
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [success, SetSuccess] = useState(false);
  const _resetUserPasswordAsync = async (username, password, jwt) => {
    const passwordMgr = PasswordManager.getInstance();

    const resetPwdResponse = await passwordMgr.resetPasswordAsync(
      username,
      password,
      jwt
    );

    const error = resetPwdResponse.errorName;

    if (!error) {
      SetSuccess(true);
    } else {
      message.error(<Trans>ResetPasswordFailed</Trans>);
    }
  };
  const onSubmit = () => {
    console.log("this.state", userEmail, jwt, password, checkPassword);
    if (password !== checkPassword) {
      message.error("兩次密碼輸入不一致！");
      return;
    }
    _resetUserPasswordAsync(userEmail, password, jwt);
  };
  useEffect(() => {
    let params = qs.parse(props.location.search.substr(1));
    let userEmail =
      params[PfnKey.KEY_USER_EMAIL] === undefined
        ? ""
        : params[PfnKey.KEY_USER_EMAIL];
    setUserEmail(userEmail);
    setJwt(params[PfnKey.KEY_JWT]);
  }, []);
  return (
    <ResetPasswordBox>
      {success && (
        <Alert
          message="Success"
          description={<Trans>ResetPasswordDone</Trans>}
          type="success"
          showIcon
          closable
        />
      )}
      <div className="login">
        <img src="./App_logo with text_01.png" alt="" />
        <p>請輸入新設定的密碼</p>
        <div className="login_form ">
          <div className="form-field pwd">
            <input
              type="password"
              placeholder="請輸入新密碼"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-field pwd">
            <input
              type="password"
              placeholder="請重新輸入密碼"
              required
              onChange={(e) => setCheckPassword(e.target.value)}
            />
          </div>
          <div className="btnset">
            <button className="btn" onClick={onSubmit}>
              確定
            </button>
          </div>
        </div>
      </div>
      <div className="footer">
        <span>計劃統籌及推展機構：禮賢會恩慈學校</span>
        <div>技術研發機構：香港應用科技研究院、播閃機械人有限公司 </div>
        <div>Copyright © 2023 Rhenish Church Grace School</div>
      </div>
    </ResetPasswordBox>
  );
};

export default withRouter(ResetPassword);
